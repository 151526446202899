import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MultiImage from '../components/MultiImage/MultiImage';
import MultiImageItem from '../components/MultiImage/MultiImageItem';

const MULTI_IMAGES_GRID_TYPES = {
  THREE_ITEMS: 'threeImagesGridItem',
  FOUR_ITEMS: 'fourImagesGridItem',
};

const MultiImages = ({ slice }) => {
  const gridType = slice.items.length === 3
    ? MULTI_IMAGES_GRID_TYPES.THREE_ITEMS : MULTI_IMAGES_GRID_TYPES.FOUR_ITEMS;
  // Map each multi images into an object with correct keys, used by MultiImage component
  const images = slice.items.map((image) => (
    <MultiImageItem
      key={`multi-image-item-${image.item_image.url}`}
      className={gridType}
      imageSrc={image.item_image.url}
      imageAlt={image.item_image.alt}
      description={image.item_description.text}
      caption={image.item_caption.text}
      subcaption={image.item_subcaption.text}
      title={image.item_title.text}
      ctaUrl={image.item_cta_url.url}
      ctaText={image.item_cta_text.text}
      isCtaInternal={image.is_cta_internal}
    />
  ));

  return (
    <MultiImage
      title={slice.primary.multi_image_title.text}
      subtitle={slice.primary.multi_image_subtitle.text}
      ctaText={slice.primary.multi_image_cta_text.text}
      ctaUrl={slice.primary.multi_image_cta_url.url}
      isCtaInternal={slice.primary.is_cta_internal}
      images={images}
    />
  );
};

MultiImages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  slice: PropTypes.object.isRequired,
};

export const query = graphql`
fragment PageDataBodyMultiImage on PrismicPageDataBodyMultiImage {
  items {
    is_cta_internal
    item_cta_text {
      text
    }
    item_cta_url {
      url
    }
    item_description {
      text
    }
    item_image {
      alt
      url
    }
    item_caption {
      text
    }
    item_subcaption {
      text
    }
    item_title {
      text
    }
  }
  primary {
    is_cta_internal
    multi_image_subtitle {
      text
    }
    multi_image_title {
      text
    }
    multi_image_cta_url {
      url
    }
    multi_image_cta_text {
      text
    }      
  }
}
`;

export default MultiImages;
