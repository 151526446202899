import React from 'react';
import PropTypes from 'prop-types';

import Text from '../shared/Text';
import Divider from '../shared/Divider';

const ImagesGrid = ({ title, subtitle, images }) => (
  <div>
    <div className="px-6 md:px-[30px] xl:px-[50px] xl:mb-24 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
      <div className="mb-1">
        <Text.SectionTitle text={title} />
      </div>
      <div className="mb-[40px] md:mb-16">
        <Text.PageText text={subtitle} />
      </div>
      <div className="grid grid-cols-1 gap-y-[40px] md:grid-cols-2 md:gap-x-4 xl:gap-x-12">
        {images}
      </div>
    </div>
    <Divider className="mb-16" />
  </div>
);

ImagesGrid.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ImagesGrid;
