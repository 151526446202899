import * as React from 'react';
import PropTypes from 'prop-types';

import { PlusIcon, MinusIcon } from '@heroicons/react/solid';

import Text from '../shared/Text';
import Divider from '../shared/Divider';
import CtaLink from '../shared/CtaLink';

const FAQSection = ({
  stayOpen,
  title,
  subtitle,
  ctaText,
  ctaUrl,
  isCtaInternal,
  items,
}) => {
  const [activeSections, setActiveSections] = React.useState([]);

  const updateSections = (isActive, sectionIndex) => {
    if (isActive) {
      return setActiveSections(activeSections.filter((itemIndex) => itemIndex !== sectionIndex));
    }
    return stayOpen
      ? setActiveSections([...activeSections, sectionIndex]) : setActiveSections([sectionIndex]);
  };

  const onSectionKeyDown = ({ e, isSectionActive, sectionIndex }) => {
    const macOSSpace = ' ';
    if (e.key === macOSSpace || e.key === 'Enter' || e.key === 'Spacebar') {
      updateSections(isSectionActive, sectionIndex);
    }
  };

  const renderCtaButton = (className) => (
    <div className={`pb-16 md:pb-24 ${className}`}>
      <p className="text-m-text-m pb-3 xl:pb-4">{subtitle}</p>
      <CtaLink
        ctaUrl={ctaUrl}
        ctaText={ctaText}
        isCtaInternal={isCtaInternal}
      />
    </div>
  );

  return (
    <>
      <div className="px-6 md:px-[30px] xl:px-[50px] 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
        <div className="flex flex-col xl:flex-row xl:pb-24">
          <div className="pb-8 md:pb-12 xl:min-w-1/2">
            <Text.SectionTitle text={title} className="xl:pb-2" />
            {renderCtaButton('hidden xl:block xl:pb-0')}
          </div>
          <div>
            <Divider className="pb-4" />
            {items.map((item, sectionIndex) => {
              const isSectionActive = activeSections.indexOf(sectionIndex) !== -1;
              return (
                <React.Fragment key={item.title}>
                  <div
                    role="button"
                    aria-pressed="false"
                    tabIndex={0}
                    className="cursor-pointer relative"
                    onClick={() => updateSections(isSectionActive, sectionIndex)}
                    onKeyDown={(e) => onSectionKeyDown({
                      e, isSectionActive, sectionIndex,
                    })}
                  >
                    <div>
                      <p className="pb-4 text-m-text-m font-medium max-w-[80%]">
                        {item.title}
                      </p>
                      <div className="absolute right-[5px] top-0">{isSectionActive ? <MinusIcon className="h-5 w-5" /> : <PlusIcon className="h-5 w-5" />}</div>
                    </div>
                  </div>
                  <div
                    className={`max-w-[80%] max-h-0 overflow-hidden transition-max-height duration-300 ease-out ${isSectionActive && 'max-h-28 transition-max-height duration-300 ease-in'}`}
                  >
                    <p className="text-m-text-m">{item.details}</p>
                  </div>
                  <Divider className="pb-4 last:pb-12 xl:last:pb-0" />
                </React.Fragment>
              );
            })}
          </div>
          {renderCtaButton('block xl:hidden')}
        </div>
      </div>
      <Divider className="mb-14 md:mb-[80px]" />
    </>
  );
};

FAQSection.propTypes = {
  stayOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  isCtaInternal: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      details: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FAQSection;
