import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HomepageBanner from '../components/HomepageBanner/HomepageBanner';

const HomepageHero = ({ slice }) => (
  <HomepageBanner
    title={slice.primary.title.text}
    subtitle={slice.primary.subtitle.text}
    imageSrc={slice.primary.image.url}
    ctaUrl={slice.primary.cta_url.url}
    isCtaInternal={slice.primary.is_cta_internal}
    ctaText={slice.primary.cta_text.text}
  />
);

HomepageHero.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
fragment PageDataBodyHomepageHero on PrismicPageDataBodyHomepageHero {
  primary {
    title {
      text
    }
    subtitle {
      text
    }
    cta_url {
      url
    }
    cta_text {
      text
    }
    image {
      url
    }
    is_cta_internal
  }
}
`;

export default HomepageHero;
