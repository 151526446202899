import React from 'react';
import PropTypes from 'prop-types';

import { ChevronLeftIcon } from '@heroicons/react/solid';

import NavDropdown from '../shared/NavDropdown/NavDropdown';
import SiteLink from '../shared/SiteLink';

import utils from '../../utils/utils';

const NavigationBanner = ({
  title, subtitle, links, backUrl, backText,
}) => {
  const currentPageIndex = utils.findCurrentPageIndexFromSiblings(links);
  const activeLink = links[currentPageIndex];
  return (
    <div className="px-6 md:px-[30px] xl:px-[50px] 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto ">
      <div className="mb-2 xl:hidden">
        <NavDropdown
          activeLinkText={backText || activeLink.data.title.text}
          activeLinkUrl={backUrl}
          links={links}
        />
      </div>
      <div className="hidden mb-4 xl:block">
        {backUrl ? (
          <div className="flex items-center">
            <SiteLink
              isLinkInternal
              url={backUrl}
              text={backText}
              className="flex items-center"
              leftIcon={<ChevronLeftIcon className="h-5 w-5 -mt-[1px] link-left-chevron" aria-hidden="true" />}
            />
          </div>
        ) : <p className="xl:text-l-text-s">{activeLink.data.title.text}</p>}
      </div>
      <h1 className="font-larken mb-4 max-w-full text-s-display-xl xl:text-l-display-xl xl:w-1/2 2xl:w-[40%]">{title}</h1>
      <p className="max-w-full text-s-text-m xl:text-l-text-m xl:w-[65%] 2xl:w-1/2">{subtitle}</p>
    </div>
  );
};

NavigationBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  })).isRequired,
  backUrl: PropTypes.string.isRequired,
  backText: PropTypes.string.isRequired,
};

export default NavigationBanner;
