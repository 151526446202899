import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from '@heroicons/react/solid';

const Lightbox = ({
  open,
  activeItemIndex,
  activeItem,
  itemsAmount,
  setIsOpen,
  setActiveItemIndex,
}) => {
  const onLightBoxKeyDown = (e) => {
    const leftArrowKeyCode = 'ArrowLeft';
    const rightArrowKey = 'ArrowRight';
    if (e.key === 'Escape') {
      return setIsOpen(false);
    }
    if (e.key === leftArrowKeyCode) {
      const newIndex = activeItemIndex - 1;
      return newIndex === -1 ? false : setActiveItemIndex(newIndex);
    }
    if (e.key === rightArrowKey) {
      const newIndex = activeItemIndex + 1;
      return newIndex === itemsAmount ? false : setActiveItemIndex(newIndex);
    }
    return false;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto px-6"
        onClose={() => {}}
      >
        <div
          role="button"
          tabIndex={0}
          className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 cursor-default outline-none"
          onKeyDown={onLightBoxKeyDown}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full select-none relative">
              <div className="absolute flex justify-end w-full -mt-[20px]">
                <XIcon
                  className="h-5 w-5 cursor-pointer text-white"
                  aria-hidden="true"
                  onClick={() => setIsOpen(false)}
                />
              </div>
              <div className="bg-white p-4">
                <img src={activeItem.data.preview.url} alt="Lightbox" />
              </div>
              <div className="flex items-center absolute text-white mt-4 w-full justify-between">
                <p>{activeItem.data.title.text}</p>
                <div className="flex items-center gap-x-3">
                  <ChevronLeftIcon
                    className={`${activeItemIndex === 0 ? 'invisible' : ''} h-5 w-5 cursor-pointer`}
                    aria-hidden="true"
                    onClick={() => setActiveItemIndex(activeItemIndex - 1)}
                  />
                  <ChevronRightIcon
                    className={`${activeItemIndex === itemsAmount - 1 ? 'invisible' : ''} h-5 w-5 cursor-pointer`}
                    aria-hidden="true"
                    onClick={() => setActiveItemIndex(activeItemIndex + 1)}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Lightbox.propTypes = {
  activeItemIndex: PropTypes.number.isRequired,
  activeItem: PropTypes.shape({
    data: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      preview: PropTypes.object.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      title: PropTypes.object.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      description: PropTypes.object.isRequired,
    }),
    // eslint-disable-next-line react/forbid-prop-types
  }).isRequired,
  open: PropTypes.bool.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setActiveItemIndex: PropTypes.func.isRequired,
};

export default Lightbox;
