import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SingleImage from '../components/SingleImage/SingleImage';

const SingleImages = ({ slice }) => {
  const { use_homepage_style: useHomepageStyle } = slice.primary;
  return (
    <section className="mb-[56px] md:mb-16 lg:mb-[80px] 2xl:mb-24">
      {slice.items.map((singleImage, index) => {
        const title = singleImage.title.text;
        const subtitle = singleImage.description.text;
        const subtitleRichText = singleImage.description.richText;
        const shouldShowDivider = index !== slice.items.length - 1;
        const imageOnLeft = (useHomepageStyle && index % 2 !== 0);
        return (
          <SingleImage
            key={`key-feature-${(title + subtitle)}`}
            imageSrc={singleImage.image.url}
            title={title}
            subtitle={subtitle}
            subtitleRichText={subtitleRichText}
            linkUrl={singleImage.link.url}
            linkText={singleImage.link_text.text}
            shouldDropShadow={singleImage.display_shadow}
            shouldShowDivider={shouldShowDivider}
            largeHeadings={!!useHomepageStyle}
            equalSize={!!useHomepageStyle}
            textInset={!!useHomepageStyle}
            imageOnLeft={imageOnLeft}
            isCtaInternal={singleImage.is_cta_internal}
          />
        );
      })}
    </section>
  );
};

SingleImages.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
fragment PageDataBodySingleImages on PrismicPageDataBodySingleImages {
  primary {
    use_homepage_style
  }
  items {
    title {
      text
    }
    description {
      text
      richText
    }
    image {
      url
    }
    link {
      url
    }
    link_text {
      text
    }
    display_shadow
    is_cta_internal
  }
}
`;

export default SingleImages;
