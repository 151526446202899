import * as React from 'react';
import PropTypes from 'prop-types';

import PaginationSection from '../components/PaginationSection/PaginationSection';
import utils from '../utils/utils';

const Pagination = ({ context }) => {
  const { siblings } = context;
  // Find our what index the current page is within the list of siblings
  const currentPageIndex = utils.findCurrentPageIndexFromSiblings(siblings.nodes);

  if (currentPageIndex < 0) {
    throw new Error('Cannot generate pagination, unable to find this page in siblings list');
  }

  let prevLinkUrl;
  let prevLinkText;
  let nextLinkUrl;
  let nextLinkText;

  // Then generate prev and next link details if they exist
  if (currentPageIndex > 0) {
    const prevLink = siblings.nodes[currentPageIndex - 1];
    prevLinkUrl = prevLink.url;
    prevLinkText = prevLink.data.title.text;
  }

  if (currentPageIndex < siblings.nodes.length - 1) {
    const nextLink = siblings.nodes[currentPageIndex + 1];
    nextLinkUrl = nextLink.url;
    nextLinkText = nextLink.data.title.text;
  }

  return (
    <PaginationSection
      nextLinkUrl={nextLinkUrl}
      nextLinkText={nextLinkText}
      prevLinkUrl={prevLinkUrl}
      prevLinkText={prevLinkText}
    />
  );
};

Pagination.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
  context: PropTypes.shape({
    siblings: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
      nodes: PropTypes.array,
    }),
  }).isRequired,
};

export default Pagination;
