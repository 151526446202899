import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import windowService from '../../services/window.service';

import Divider from '../shared/Divider';
import GreenButton from '../shared/GreenButton';

const PrimaryCta = ({
  title,
  ctaText,
  ctaUrl,
  backgroundImage,
  isCtaInternal,
}) => {
  const onClick = () => (isCtaInternal ? navigate(ctaUrl) : windowService.openExternalUrl(ctaUrl));
  return (
    <>
      <div className="px-6 mb-16 md:px-[30px] xl:px-[50px] 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
        <div
          style={{ backgroundImage: `url(${backgroundImage})` }}
          className="gradient-background py-[40px] md:py-12 2xl:py-16 flex flex-col items-center"
        >
          <p className="font-larken mb-4 text-white text-s-display-l md:text-m-display-l text-shadow">{title}</p>
          <GreenButton onClick={onClick} text={ctaText} />
        </div>
      </div>
      <Divider className="mb-16" />
    </>
  );
};

PrimaryCta.propTypes = {
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  isCtaInternal: PropTypes.bool.isRequired,
};

export default PrimaryCta;
