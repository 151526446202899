import React from 'react';
import PropTypes from 'prop-types';

import { ArrowNarrowRightIcon } from '@heroicons/react/solid';

const GreenButton = ({ className, text, onClick }) => (
  <button
    type="button"
    className={`px-[16px] py-[10px] flex items-center text-white bg-shamrock rounded shadow text-s-text-s 2xl:text-l-text-s ${className}`}
    onClick={onClick}
  >
    {text}
    <ArrowNarrowRightIcon className="h-5 w-5 inline ml-2" />
  </button>
);

GreenButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

GreenButton.defaultProps = {
  className: '',
};

export default GreenButton;
