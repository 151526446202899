import React from 'react';
import PropTypes from 'prop-types';

import Stars from '../../assets/stars.svg';
import Gradient from '../../assets/cta_box.jpeg';

import GreenButton from '../shared/GreenButton';

import windowService from '../../services/window.service';

const HighlightedImage = ({
  title, comment, description, ctaText, ctaUrl, image,
}) => (
  <div style={{ backgroundImage: `url(${Gradient})` }} className="gradient-background">
    <div className="mb-16 px-6 py-12 md:py-16 md:px-[50px] lg:px-[110px] xl:px-[50px] 2xl:px-[112px] 2xl:max-w-screen-2xl mx-auto">
      <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-[52px] 2xl:gap-x-[84px] items-center">
        <div className="mb-8 md:mb-[40px] xl:mb-0">
          <img src={image} alt="Highlight" />
        </div>
        <div className="text-white">
          <p className="font-larken text-s-display-l md:text-m-display-l 2xl:text-l-display-l mb-2">
            {title}
          </p>
          <div className="mb-6">
            <img src={Stars} alt="Stars" />
          </div>
          <div>
            <p className="text-s-text-all-caps md:text-m-text-l 2xl:text-l-text-l mb-8 md:mb-12 2xl:mb-16">
              {description}
            </p>
            <p className="text-s-text-m md:text-m-text-m 2xl:text-l-text-m mb-4">{comment}</p>
            <GreenButton onClick={() => windowService.openExternalUrl(ctaUrl)} text={ctaText} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

HighlightedImage.propTypes = {
  title: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default HighlightedImage;
