const homepageBannerStyles = {
  section: 'bg-dark-cream',
  bannerContent: [
    'w-full relative px-6 pb-[32px] mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl flex flex-col',
    'md:px-[30px] md:pb-[48px]',
    'lg:flex-row',
    'xl:min-h-[560px]',
    '2xl:min-h-[800px]',
  ].join(' '),
};

export default homepageBannerStyles;
