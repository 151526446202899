import React from 'react';
import PropTypes from 'prop-types';
import SiteLink from '../shared/SiteLink';
import Divider from '../shared/Divider';

const PaginationSection = ({
  prevLinkUrl, prevLinkText, nextLinkUrl, nextLinkText,
}) => (
  <>
    <div className="px-6 md:px-[30px] xl:px-[50px] 2xl:px-[130px] 2xl:max-w-screen-2xl mx-auto">
      <div className="flex justify-between mb-24 text-l-display-s font-larken">
        {prevLinkUrl ? (
          <div>
            <SiteLink url={prevLinkUrl} text={`« Prev: ${prevLinkText}`} isLinkInternal />
          </div>
        ) : null}
        {nextLinkUrl ? (
          <div className="ml-auto">
            <SiteLink url={nextLinkUrl} text={`Next: ${nextLinkText} »`} isLinkInternal />
          </div>
        ) : null}
      </div>
    </div>
    <Divider className="mb-16" />
  </>
);

PaginationSection.propTypes = {
  prevLinkUrl: PropTypes.string.isRequired,
  prevLinkText: PropTypes.string.isRequired,
  nextLinkUrl: PropTypes.string.isRequired,
  nextLinkText: PropTypes.string.isRequired,
};

export default PaginationSection;
