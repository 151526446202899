import React from 'react';
import PropTypes from 'prop-types';

import CtaLink from '../shared/CtaLink';

const ImagesGridItem = ({
  imageSrc, title, description, ctaUrl, ctaText, isCtaInternal,
}) => (
  <div className="flex flex-col xl:flex-row xl:flex-row-reverse xl:gap-x-8">
    <div className="mb-4 xl:mb-0">
      <img src={imageSrc} alt="Images Grid" />
    </div>
    <div className="xl:mb-0 xl:max-w-[48%]">
      <p className="font-larken text-s-display-s md:text-m-display-s 2xl:text-l-display-s mb-2">
        {title}
      </p>
      <p className="text-s-text-s md:text-m-text-m 2xl:text-l-text-s mb-4">
        {description}
      </p>
      {ctaUrl ? (
        <CtaLink
          ctaUrl={ctaUrl}
          ctaText={ctaText}
          isCtaInternal={isCtaInternal}
        />
      ) : null}
    </div>
  </div>
);

ImagesGridItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  isCtaInternal: PropTypes.bool.isRequired,
};

ImagesGridItem.defaultProps = {
  ctaUrl: '',
  ctaText: '',
};

export default ImagesGridItem;
