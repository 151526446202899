import React from 'react';
import PropTypes from 'prop-types';

const DotGrid = ({ right }) => (
  <div className={`dot-grid absolute ${right ? 'right' : ''} `} />
);

DotGrid.propTypes = {
  right: PropTypes.bool,
};

DotGrid.defaultProps = {
  right: false,
};

export default DotGrid;
