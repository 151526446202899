import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import FAQSection from '../components/FAQSection/FAQSection';

const Faq = ({ slice }) => {
  const items = slice.items.map((faq) => ({
    title: faq.faq_question.text,
    details: faq.faq_answer.text,
  }));

  return (
    <FAQSection
      stayOpen
      title={slice.primary.faq_title.text}
      subtitle={slice.primary.faq_subtitle.text}
      ctaText={slice.primary.faq_cta_text.text}
      ctaUrl={slice.primary.faq_cta_url.url}
      isCtaInternal={slice.primary.is_cta_internal}
      items={items}
    />
  );
};

Faq.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
  primary {
    is_cta_internal
    faq_cta_text {
      text
    }
    faq_cta_url {
      url
    }
    faq_subtitle {
      text
    }
    faq_title {
      text
    }
  }
  items {
    faq_answer {
      text
    }
    faq_question {
      text
    }
  }
}
`;

export default Faq;
