import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ImagesGrid from '../components/ImagesGrid/ImagesGrid';
import ImagesGridItem from '../components/ImagesGrid/ImagesGridItem';

const UseCases = ({ slice }) => {
  const useCases = slice.items.map((useCase) => (
    <ImagesGridItem
      key={`image-grid-item-${useCase.usecase_title.text + useCase.usecase_image.url}`}
      imageSrc={useCase.usecase_image.url}
      title={useCase.usecase_title.text}
      description={useCase.usecase_description.text}
      ctaText={useCase.usecase_cta_text.text}
      ctaUrl={useCase.usecase_cta_url.url}
      isCtaInternal={useCase.is_cta_internal}
    />
  ));

  return (
    <ImagesGrid
      title={slice.primary.usecases_title.text}
      subtitle={slice.primary.usecases_subtitle.text}
      images={useCases}
    />
  );
};

UseCases.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
fragment PageDataBodyUseCases on PrismicPageDataBodyUseCases {
  primary {
    usecases_subtitle {
      text
    }
    usecases_title {
      text
    }
  }
  items {
    is_cta_internal
    usecase_title {
      text
    }
    usecase_description {
      text
    }
    usecase_cta_text {
      text
    }
    usecase_cta_url {
      url
    }
    usecase_image {
      url
    }
  }
}
`;

export default UseCases;
