import React from 'react';
import PropTypes from 'prop-types';

import Divider from '../shared/Divider';
import Text from '../shared/Text';
import CtaLink from '../shared/CtaLink';

const MultiImage = ({
  title, subtitle, ctaText, ctaUrl, isCtaInternal, images,
}) => {
  const getImagesWrapperClassName = () => {
    const maxWidthClassName = images.length === 3 ? '2xl:grid-cols-3' : '2xl:grid-cols-4';
    return `mb-12 grid grid-cols-1 gap-y-[40px] md:gap-x-[20px] md:grid-cols-2 xl:grid-cols-3 ${maxWidthClassName}`;
  };
  return (
    <>
      <div className="px-6 md:px-[30px] xl:px-[34px] 2xl:px-[112px] 2xl:max-w-screen-2xl mx-auto">
        <div className="mb-[40px] md:mb-12 xl:mb-16">
          <Text.SectionTitle text={title} className="xl:mb-2" />
          <Text.PageText text={subtitle} />
        </div>
        <div className={getImagesWrapperClassName()}>
          {images}
        </div>
        {ctaUrl ? (
          <CtaLink
            ctaUrl={ctaUrl}
            ctaText={ctaText}
            isCtaInternal={isCtaInternal}
            className="mb-16"
          />
        ) : null}
      </div>
      <Divider className="mb-16 md:mb-24" />
    </>
  );
};

MultiImage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  isCtaInternal: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default MultiImage;
