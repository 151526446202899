import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import HighlightedImage from '../components/HighlightedImage/HighlightedImage';

const HighlightedImages = ({ slice }) => (
  <HighlightedImage
    title={slice.primary.title1.text}
    comment={slice.primary.comment.text}
    description={slice.primary.description1.text}
    ctaText={slice.primary.cta_text.text}
    ctaUrl={slice.primary.cta_url.url}
    image={slice.primary.image.url}
  />
);

HighlightedImages.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
  fragment PageDataBodyHighlightedImage on PrismicPageDataBodyHighlightedImage {
    primary {
      comment {
        text
      }
      cta_text {
        text
      }
      cta_url {
        url
      }
      description1 {
        text
      }
      image {
        url
      }
      title1 {
        text
      }
    }
  }
`;

export default HighlightedImages;
