import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import DotGrid from '../shared/DotGrid';
import Gradient from './Gradient';
import Text from '../shared/Text';
import GreenButton from '../shared/GreenButton';

import windowService from '../../services/window.service';

import homepageBannerStyles from './homepageBanner.styles';

const HomepageBanner = ({
  title,
  subtitle,
  ctaText,
  ctaUrl,
  isCtaInternal,
  imageSrc,
}) => {
  const onClick = () => (isCtaInternal ? navigate(ctaUrl) : windowService.openExternalUrl(ctaUrl));
  return (
    <section className={homepageBannerStyles.section}>
      <div className={homepageBannerStyles.bannerContent}>
        <div className="banner-image-wrapper md:mb-2 mt-[20px] md:mt-[85px] lg:mt-[55px] xl:mt-[55px] 2xl:mt-[75px]">
          <DotGrid />
          <img src={imageSrc} alt="Homepage banner" />
          <DotGrid right />
        </div>
        <div className="relative mt-[260px] md:mt-[420px] lg:mt-[80px] lg:ml-[320px] xl:ml-[490px] xl:pr-[85px] 2xl:mt-[185px] 2xl:ml-[700px] 2xl:pr-[145px]">
          <div className="md:pr-[90px] lg:pr-0">
            <div className="mb-4 md:mb-[20px]">
              <Text.BannerTitle text={title} />
            </div>
            <div className="mb-8 lg:mt-5">
              <Text.PageText text={subtitle} />
            </div>
            <GreenButton onClick={onClick} text={ctaText} />
          </div>
        </div>
      </div>
      <Gradient />
    </section>
  );
};

HomepageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isCtaInternal: PropTypes.bool.isRequired,
};

export default HomepageBanner;
