import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { SliceZone } from '@prismicio/react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import components from '../slices';

const PageTemplate = ({ data }) => {
  if (!data) return null;
  const page = data.prismicPage;
  return (
    <Layout transparentHeader={page.data.transparent_header} id={page.prismicId}>
      <Seo title={page.data.title?.text} description={page.data.description?.text} />
      <SliceZone slices={page.data.body} components={components} context={data} />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    prismicPage: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    siblings: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    uncles: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    pageDesignsByStyle: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    pageDesignsByType: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
query PageQuery($id: String, $prismicId: ID, $parentPrismicId: ID, $grandparentPrismicId: ID, $uid: String) {
    prismicPage(id: { eq: $id }) {
      data {
        transparent_header
        title {
          text
        }
        description {
          text
        }
        parent {
            document {
              ... on PrismicPage {
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
          }
          ...PageDataBodyHomepageHero
          ...PageDataBodyNavigationHero
          ...PageDataBodySingleImages
          ...PageDataBodyMultiImage
          ...PageDataBodyFaq
          ...PageDataBodyUseCases
          ...PageDataBodyRequestSample
          ...PageDataBodyHighlightedImage
          ...PrismicDataBodyDesignGallery
        }
      }
      id
      prismicId
    }
    children: allPrismicPage(
        filter: {data: {parent: {id: {eq: $prismicId}}}}
        sort: {order: ASC, fields: data___order}
    ) {
      nodes {
        url
        uid
        data {
          preview {
              url
          }
          title {
              text
          }
          description {
              text
          }
        }
      }
    }
    siblings: allPrismicPage(
        filter: {data: {parent: {id: {eq: $parentPrismicId}}}}
        sort: {order: ASC, fields: data___order}
    ) {
      nodes {
        url
        uid
        data {
          preview {
              url
          }
          title {
              text
          }
          description {
              text
          }
        }
      }
    }
    uncles: allPrismicPage(
        filter: {data: {parent: {id: {eq: $grandparentPrismicId}}}}
        sort: {order: ASC, fields: data___order}
    ) {
      nodes {
        url
        data {
          title {
            text
          }
          description {
            text
          }
        }
        
      }
    }
    pageDesignsByStyle: allPrismicPageDesign(filter: {data: {style: {eq: $uid}}}) {
      nodes {
        data {
          type
          style
          preview {
            url
          }
          title {
            text
          }
          description {
            text
          }
        }
      }        
    }
    pageDesignsByType: allPrismicPageDesign(filter: {data: {type: {eq: $uid}}}) {
      nodes {
        data {
          type
          style
          preview {
            url
          }
          title {
            text
          }
          description {
            text
          }
        }
      }        
    }
}
`;

export default PageTemplate;
