import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Gallery from '../components/Gallery/Gallery';

const DesignGallery = ({ slice, context }) => {
  let items;
  switch (slice.primary.gallery_type) {
    case 'page-types':
      items = context.pageDesignsByType;
      break;
    case 'styles':
      items = context.pageDesignsByStyle;
      break;
    case 'children':
      items = context.children;
      break;
    default:
      throw new Error(`Unknown gallery type: ${slice.primary.gallery_type}`);
  }

  return (
    <section className="mb-[56px]">
      <Gallery
        title={slice.primary.gallery_title.text}
        items={items.nodes}
      />
    </section>
  );
};

DesignGallery.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
  context: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    pageDesignsByType: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    pageDesignsByStyle: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
  fragment PrismicDataBodyDesignGallery on PrismicPageDataBodyDesignGallery {
    primary {
      gallery_title {
        text
      }
      gallery_type
    }
  }
`;

export default DesignGallery;
