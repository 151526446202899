import SingleImages from './SingleImages';
import MultiImages from './MultiImages';
import Faq from './Faq';
import UseCases from './UseCases';
import RequestSample from './RequestSample';
import HomepageHero from './HomepageHero';
import NavigationHero from './NavigationHero';
import HighlightedImage from './HighlightedImage';
import Pagination from './Pagination';
import DesignGallery from './DesignGallery';

const components = {
  homepage_hero: HomepageHero,
  navigation_hero: NavigationHero,
  single_images: SingleImages,
  multi_image: MultiImages,
  faq: Faq,
  use_cases: UseCases,
  request_sample: RequestSample,
  highlighted_image: HighlightedImage,
  pagination: Pagination,
  design_gallery: DesignGallery,
};

export default components;
