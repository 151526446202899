import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../shared/Text';
import GalleryItem from './GalleryItem';

const Gallery = ({ title, items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  const onSetActiveItemIndex = (index) => setActiveItemIndex(index);

  return (
    <div>
      <div className="px-6 md:px-[30px] xl:px-[50px] xl:mb-24 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
        <div className="mb-1">
          <Text.SectionTitle text={title} />
        </div>
        <div className="grid grid-cols-1 gap-y-[20px] md:gap-y-8 md:grid-cols-2 md:gap-x-4 xl:grid-cols-3 xl:gap-x-[20px]">
          {items.map((item, index) => (
            <div key={`key-${item.data.preview.url}`}>
              <GalleryItem
                url={item.data.preview.url}
                title={item.data.title.text}
                subtitle={item.data.description.text}
                activeItem={items[activeItemIndex]}
                activeItemIndex={activeItemIndex}
                itemIndex={index}
                itemsAmount={items.length}
                setActiveItemIndex={onSetActiveItemIndex}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    preview: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    title: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    description: PropTypes.object.isRequired,
  })).isRequired,
};

export default Gallery;
