import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import Divider from '../shared/Divider';
import Text from '../shared/Text';
import CtaLink from '../shared/CtaLink';

const SingleImage = ({
  imageSrc,
  title,
  subtitle,
  subtitleRichText,
  linkUrl,
  linkText,
  imageOnLeft,
  equalSize,
  largeHeadings,
  textInset,
  shouldDropShadow,
  shouldShowDivider,
  isCtaInternal,
}) => {
  const sectionClassName = 'px-6 md:px-[30px] lg:px-[90px] xl:px-[50px] xl:flex-row xl:items-center 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto';

  const getWrapperClassName = () => `grid grid-cols-1 ${equalSize ? 'xl:grid-cols-2' : 'xl:grid-cols-4/8'} xl:gap-x-[52px] 2xl:gap-x-[84px] items-center`;

  const getTextWrapperClassName = () => {
    if (!textInset) {
      return '';
    }
    return imageOnLeft ? 'xl:pr-8 2xl:pr-16' : 'xl:pl-8 2xl:pl-16';
  };

  const renderHeading = () => (largeHeadings ? <Text.SectionTitle text={title} /> : (
    <p className="font-larken text-s-display-l md:text-s-display-m">
      {title}
    </p>
  ));

  return (
    <>
      <div className={sectionClassName}>
        <div className={getWrapperClassName()}>
          <div className={`mb-8 md:mb-[40px] xl:mb-0 ${shouldDropShadow ? 'image-shadow' : ''}`}>
            <img src={imageSrc} alt="TODO: Get alt from CMS" />
          </div>
          <div className={`${getTextWrapperClassName()} ${imageOnLeft ? '' : 'xl:row-start-1'}`}>
            <div className="mb-2">
              {renderHeading()}
            </div>
            <div className={`${linkUrl ? 'mb-8' : ''}`}>
              {subtitleRichText
                ? RichText.render(subtitleRichText) : <Text.PageText text={subtitle} />}
            </div>
            {linkUrl ? (
              <CtaLink
                ctaUrl={linkUrl}
                ctaText={linkText}
                isCtaInternal={isCtaInternal}
              />
            ) : null}
          </div>
        </div>
        <Divider className={`my-12 lg:my-[56px] xl:my-12 2xl:my-24 ${!shouldShowDivider ? 'hidden' : ''}`} />
      </div>
    </>
  );
};

SingleImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subtitleRichText: PropTypes.array,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  shouldDropShadow: PropTypes.bool,
  imageOnLeft: PropTypes.bool,
  shouldShowDivider: PropTypes.bool,
  equalSize: PropTypes.bool,
  largeHeadings: PropTypes.bool,
  textInset: PropTypes.bool,
  isCtaInternal: PropTypes.bool,
};

SingleImage.defaultProps = {
  linkUrl: null,
  linkText: null,
  shouldDropShadow: false,
  imageOnLeft: true,
  shouldShowDivider: true,
  equalSize: false,
  largeHeadings: false,
  subtitleRichText: '',
  textInset: true,
  isCtaInternal: true,
};

export default SingleImage;
