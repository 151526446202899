import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import NavigationBanner from '../components/NavigationBanner/NavigationBanner';

const NavigationHero = ({ slice, context }) => {
  const { parent } = context.prismicPage.data;

  // Sets the back link if the parent is a page with a title.
  // Otherwise the active page name will be used.
  let backUrl;
  let backText;
  if (parent?.document?.data?.title?.text) {
    backUrl = parent.document.url;
    backText = parent.document.data.title.text;
  }

  return (
    <section className="my-12 xl:my-16">
      <NavigationBanner
        title={slice.primary.nav_hero_title.text}
        subtitle={slice.primary.nav_hero_subtitle.text}
        links={context.siblings.nodes}
        backUrl={backUrl}
        backText={backText}
      />
    </section>
  );
};

NavigationHero.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
  }).isRequired,
  context: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    siblings: PropTypes.object,
    prismicPage: PropTypes.shape({
      data: PropTypes.shape({
        parent: PropTypes.shape({
          document: PropTypes.shape({
            url: PropTypes.string,
            data: PropTypes.shape({
              title: PropTypes.shape({
                text: PropTypes.string,
              }),
            }),
          }),
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  fragment PageDataBodyNavigationHero on PrismicPageDataBodyNavigationHero {
    primary {
      nav_hero_subtitle {
        text
      }
      nav_hero_title {
        text
      }
    }
  }
`;

export default NavigationHero;
