import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ArrowNarrowRightIcon } from '@heroicons/react/solid';

const CtaLink = ({
  ctaUrl, ctaText, isCtaInternal, className,
}) => (
  isCtaInternal ? (
    <Link to={ctaUrl} className={`flex items-center text-marguerite text-s-text-s xl:text-l-text-s ${className}`}>
      {ctaText}
      <ArrowNarrowRightIcon className="h-5 w-5 inline" />
    </Link>
  ) : (
    <a href={ctaUrl} className={`flex items-center text-marguerite text-s-text-s xl:text-l-text-s ${className}`}>
      {ctaText}
      <ArrowNarrowRightIcon className="h-5 w-5 inline" />
    </a>
  )
);

CtaLink.propTypes = {
  ctaUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  isCtaInternal: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

CtaLink.defaultProps = {
  className: '',
};

export default CtaLink;
