import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from './Lightbox';

const GalleryItem = ({
  url,
  title,
  subtitle,
  itemIndex,
  activeItemIndex,
  activeItem,
  itemsAmount,
  setActiveItemIndex,
}) => {
  const [shouldShowLightBox, setShouldShowLightBox] = useState(false);
  const openLightBox = () => {
    setActiveItemIndex(itemIndex);
    setShouldShowLightBox(true);
  };
  const onGalleryItemKeyDown = (e) => {
    if (e.key === 'Enter') {
      openLightBox();
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      className="cursor-pointer outline-none"
      onClick={openLightBox}
      onKeyDown={onGalleryItemKeyDown}
    >
      <div className="mb-4">
        <img src={url} alt="gallery-item" />
      </div>
      <div className="mb-2">
        <p className="font-larken text-l-display-s">{title}</p>
      </div>
      {subtitle ? (
        <div>
          <p className="text-l-text-s">{subtitle}</p>
        </div>
      ) : null}
      {activeItem ? (
        <Lightbox
          open={shouldShowLightBox}
          activeItem={activeItem}
          activeItemIndex={activeItemIndex}
          setActiveItemIndex={setActiveItemIndex}
          itemsAmount={itemsAmount}
          setIsOpen={setShouldShowLightBox}
        />
      ) : null}
    </div>
  );
};

GalleryItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  activeItemIndex: PropTypes.number,
  activeItem: PropTypes.shape({
    data: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      preview: PropTypes.object.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      title: PropTypes.object.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      description: PropTypes.object.isRequired,
    }),
    // eslint-disable-next-line react/forbid-prop-types
  }),
  setActiveItemIndex: PropTypes.func.isRequired,
  itemsAmount: PropTypes.number.isRequired,
};

GalleryItem.defaultProps = {
  subtitle: '',
  activeItemIndex: null,
  activeItem: null,
};

export default GalleryItem;
