import React from 'react';
import PropTypes from 'prop-types';

const BannerTitle = ({ text, className }) => (
  <p className={`font-larken text-s-display-xxl md:text-m-display-xxl 2xl:text-l-display-xxl ${className}`}>
    {text}
  </p>
);

const SectionTitle = ({ text, className }) => (
  <p className={`font-larken text-s-display-l md:text-m-display-l 2xl:text-l-display-l ${className}`}>
    {text}
  </p>
);

const PageText = ({ text, className }) => (
  <p className={`text-s-text-m md:text-m-text-m 2xl:text-l-text-m ${className}`}>
    {text}
  </p>
);

BannerTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PageText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BannerTitle.defaultProps = {
  className: '',
};

PageText.defaultProps = {
  className: '',
};

SectionTitle.defaultProps = {
  className: '',
};

const TextComponents = {
  BannerTitle,
  PageText,
  SectionTitle,
};

export default TextComponents;
