import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import PrimaryCta from '../components/PrimaryCta/PrimaryCta';

const RequestSample = ({ slice }) => (
  <PrimaryCta
    isCtaInternal={slice.primary.is_cta_internal}
    title={slice.primary.request_sample_text.text}
    ctaText={slice.primary.request_sample_cta_text.text}
    ctaUrl={slice.primary.request_sample_cta_url.url}
    backgroundImage={slice.primary.background_image.url}
  />
);

RequestSample.propTypes = {
  slice: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    primary: PropTypes.object,
  }).isRequired,
};

export const query = graphql`
fragment PageDataBodyRequestSample on PrismicPageDataBodyRequestSample {
  primary {
    is_cta_internal
    request_sample_text {
      text
    }
    request_sample_cta_text {
      text
    }
    request_sample_cta_url {
      url
    }
    background_image {
      url
    }
  }
}
`;

export default RequestSample;
