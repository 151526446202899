import React from 'react';
import PropTypes from 'prop-types';

import CtaLink from '../shared/CtaLink';

const MultiImageItem = ({
  imageSrc,
  imageAlt,
  description,
  caption,
  subcaption,
  title,
  ctaUrl,
  ctaText,
  isCtaInternal,
  className,
}) => (
  <div className={className}>
    <div className="mb-6">
      <img src={imageSrc} alt={imageAlt} />
    </div>
    {title ? (
      <div className="mb-8">
        <p className="font-larken text-l-display-m">{title}</p>
      </div>
    ) : null}
    <p className="mb-4 text-s-text-m md:text-m-text-m 2xl:text-l-text-m">
      {description}
    </p>
    {caption ? (
      <p className="font-medium text-s-text-s md:text-m-text-s 2xl:text-l-text-s">
        {caption}
      </p>
    ) : null}
    {subcaption ? (
      <p className="font-medium text-s-text-s md:text-m-text-s 2xl:text-l-text-s">
        {subcaption}
      </p>
    ) : null}
    {ctaUrl ? (
      <CtaLink
        ctaUrl={ctaUrl}
        ctaText={ctaText}
        isCtaInternal={isCtaInternal}
      />
    ) : null}
  </div>
);

MultiImageItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  subcaption: PropTypes.string.isRequired,
  title: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  isCtaInternal: PropTypes.bool,
  className: PropTypes.string,
};

MultiImageItem.defaultProps = {
  title: '',
  ctaUrl: '',
  ctaText: '',
  isCtaInternal: true,
  className: '',
};

export default MultiImageItem;
